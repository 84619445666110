import Vue from 'vue'

export function withFormData(component, api, validationSchema) {
  return Vue.component('withFormData', {
    props: ['data'],
    render(createElement) {
      return createElement(component, {
        props: {
          validationSchema,
          isSubmitting: this.isSubmitting,
          data: this.data,
          ...this.$props.data
        },
        on: {
          submit: this.handleSubmit,
          success: this.handleSuccess,
          showCode: this.toggleShowCode,
          showVast: this.toggleShowVast
        }
      })
    },
    data() {
      return {
        isSubmitting: false
      }
    },
    methods: {
      handleSubmit(data) {
        this.isSubmitting = true

        api(data)
          .then(() => this.$emit('success'))
          .catch((e) => {
            this.$emit('error', e)
            this.$children[0].$refs.form.setErrors(e.response.data.errors)
          })
          .finally(() => {
            this.isSubmitting = false
          })
      },
      handleSuccess() {
        this.$emit('success')
      },
      toggleShowCode(id) {
        this.$emit('show-code', id)
      },

      toggleShowVast(id) {
        this.$emit('show-vast', id)
      }
    }
  })
}
