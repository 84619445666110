export const CREATE_MANAGER_VALIDATION_SCHEMA = {
  name: 'required',
  email: 'required|email',
  password: 'required|min:8'
}

export const UPDATE_MANAGER_VALIDATION_SCHEMA = {
  name: 'required',
  email: 'required|email',
  password: 'min:8'
}

export const CREATE_ADMIN_VALIDATION = {
  name: 'required',
  email: 'required|email',
  password: 'required|min:8'
}

export const CREATE_PARTNER_VALIDATION = {
  name: 'required|min:3|max:254',
  email: 'required|email',
  password: 'required|min:8',
  contacts: 'max:4096',
  sites: 'max:4096',
  legal_entity: 'required|min:3|max:254',
  inn: 'required|digits_between:10,12',
  kpp: 'digits:9',
  ogrn: 'digits_between:12,16',
  business_address: 'min:3|max:4096',
  postal_address: 'min:3|max:4096',
  checking_account: 'digits:20',
  correspondent_account: 'digits:20',
  bank_name: 'min:3|max:254',
  bank_bik: 'digits:9',
  partnership_started_at: undefined,
  number_of_contract: 'min:3|max:254',
  manager_id: 'required'
}

export const UPDATE_PARTNER_VALIDATION = { ...CREATE_PARTNER_VALIDATION, password: undefined }

export const CREATE_CAMPAIGN_VALIDATION = {
  name: 'required|min:3',
  advertiser_id: 'required',
  tag: 'required|url',
  weight: 'required|integer|min_value:1|max_value:100',
  max_daily_requests_per_ip: 'integer|min_value:1',
  max_impressions_per_day: 'integer|min_value:1',
  max_days_with_continuous_impressions: 'integer|min_value:1',
  timeout_on_continuous_limit_reached: 'integer|min_value:86400',
  limit_init_day: 'integer|min_value:0',
  chance: 'integer|min_value:0|max_value:100',
  cookie_matching_id: 'required|integer',
  advertisement_type_id: 'required',
  payment_model_type_id: 'required|integer',
  domain_whitelist: 'url|min:1|max:4096',
  daily_limit_user: 'integer|min_value:1',
  domain_blacklist: 'url|min:1|max:4096',
  device_type_id: undefined,
  geo_id: undefined,
  frequency: 'integer',
  whitelist_geo_ids: 'min:1',
  blacklist_geo_ids: 'min:1',
  main_cpm: 'required|min_value:0',
  placements: 'required'
}

export const CREATE_PLACEMENT_VALIDATION = {
  advertisement_types: 'required',
  name: 'required|min:3',
  publishers: undefined,
  campaigns: 'required',
  platform: 'required',
  type: 'required',
  comission_static: 'required|comission',
  comission_dynamic: 'required|min_value:1|max_value:70',
  verification: undefined
}

export const CREATE_PLATFORM_VALIDATION = {
  name: 'required|min:3',
  publishers: 'required',
  advertisement_types: 'required',
  category_ids: 'required',
  comment: undefined
}
