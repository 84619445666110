<template>
  <div v-if="isLoadingLists" class="d-flex justify-content-center align-items-center mt-5">
    <b-spinner variant="primary" label="Spinning" />
  </div>
  <validation-observer v-else ref="form" v-slot="{ invalid, errors }">
    <BForm class="py-2 placements-form" @submit.prevent="handleSubmit">
      <b-row>
        <b-col class="d-flex flex-column justify-content-end w-100">
      <form-input id="name" class="w-100" label="Название" v-model="formData.name" vid="name" :rules="validationSchema.name" />
        </b-col>

        <b-col v-if="can('manage', 'placements-cpm')" class="d-flex flex-column w-100">

      <form-input id="static" class="w-100" @blur="setComission(formData.publisher_commission_static)" @input="setComissionLabel" v-model="formData.publisher_commission_static" :label="comissionLabel" type="number" vid="static" :rules="validationSchema.comission_static" />
        </b-col>
      </b-row>
      <b-row class="min-height-360 mt-2 campaign-form__multiselect">
        <b-col class="campaign-form__multiselect-leftcol" id="to-filter" sm="3">
          <ToFilterSelect :model-value="filterToShow" @update:model-value="setFilterToShow"
                          :proxy-names="proxyForFilterSelect"
                          :filters-options="filtersOptions" :state="formData" :errors="errors"
                          :facets="namedLists" />
        </b-col>

        <b-col v-show="filterToShow === 'platforms'" class="placements-form__select-col">
          <b-row class="d-flex" style="gap: 20px">
            <div class="col pr-0 mt-1 mt-md-0">
            <form-input-wrap
                id="platform_id"
                vid="platform_id"
                class="w-100"
                label="Площадка"
                label-hidden
                :rules="validationSchema.platform"
            >
            <MultiselectCheckboxesWithValidation
                class="flex-1-1-0"
                name="platform_id"
                :model-value="formData.platform_id"
                @update:model-value="pickMultiselect($event, 'platform_id')"
                :facets="platformsList"
                v-model="formData.platform_id"
                :state="formData.platform_id"
                single
                track-by="id"
                is-paginated
                :lists="namedLists"
                @paginate="handlePaginateList"
                @search="handleListsSearch"/>
            </form-input-wrap>
            </div>
          </b-row>
        </b-col>

        <b-col v-if="can('read', 'publishers-list')" v-show="filterToShow === 'publishers'" class="placements-form__select-col">
          <b-row class="d-flex" style="gap: 20px">
            <div class="col pr-0 mt-1 mt-md-0">
            <MultiselectCheckboxesWithValidation
                class="flex-1-1-0"
                name="publisher_id"
                :facets="publishersList"
                :model-value="publisher_id"
                @update:model-value="pickPublisher($event, 'publisher_id')"
                single
                track-by="id"
                is-paginated
                :lists="namedLists"
                @paginate="handlePaginateList"
                @search="handleListsSearch"/>
            </div>
          </b-row>
        </b-col>

        <b-col v-show="filterToShow === 'advertisement_types'" class="placements-form__select-col">
          <b-row class="d-flex" style="gap: 20px">
            <div class="col pr-0 mt-1 mt-md-0">
            <form-input-wrap
                id="advertisement_types"
                vid="advertisement_types"
                class="w-100"
                label="Тип рекламы"
                label-hidden
                :rules="validationSchema.advertisement_types"
            >
            <MultiselectCheckboxesWithValidation
                class="flex-1-1-0"
                name="advertisement_types"
                :facets="advertisementTypesList"
                v-model="formData.advertisement_types"
                :state="formData.advertisement_types"
                :model-value="formData.advertisement_types"
                @update:model-value="pickAdvertisementTypes($event, 'advertisement_types')"
                :disable-method="disableForAdvertisementType"
                track-by="id"
                :is-searchable="false"/>
            </form-input-wrap>
            </div>
          </b-row>
        </b-col>

<!--        <b-col sm="9" v-if="can('manage', 'advertising-campaigns-placements')" v-show="filterToShow === 'dsps'">-->
<!--        -->
<!--        </b-col>-->

        <b-col v-if="can('manage', 'advertising-campaigns-placements')" v-show="filterToShow === 'campaigns'" class="placements-form__select-col">
          <b-row class="d-flex" >
            <MultiselectCheckboxesWithValidation
               class="col-12 col-sm-6 mt-1 mt-md-0 mb-1 mb-md-0 pr-0"
                :model-value="pickedAdvertiser"
                @update:model-value="pickAdvertiser"
                name="dsps"
                :facets="advertisersList"
                :state="pickedAdvertiser"
                track-by="id"
                label="DSP"
                is-paginated
                :lists="namedLists"
               @pick-all="pickAllItems"
                @paginate="handlePaginateList"
                @search="handleListsSearch"/>

            <form-input-wrap
                id="campaigns"
                vid="campaigns"
                class="col-12 col-sm-6 mt-1 mt-md-0 mb-1 mb-md-0 pr-0"
                label="РК"
                label-hidden
                :rules="validationSchema.campaigns"
            >
            <MultiselectCheckboxesWithValidation
                class=""
                name="campaigns"
                :model-value="formData.campaigns"
                @update:model-value="pickMultiselect($event, 'campaigns')"
                :facets="campaignsList"
                v-model="formData.campaigns"
                :state="formData.campaigns"
                track-by="id"
                label="РК"
                is-paginated
                no-result-text="Не найдено подходящих РК. Попробуйте выбрать другого рекламодателя или снизить комиссию"
                :is-loading="isLoadingCampaigns"
                :lists="namedLists"
                @pick-all="pickAllItems"
                @paginate="handlePaginateList"
                @search="handleListsSearch"/>
            </form-input-wrap>
          </b-row>
        </b-col>
      </b-row>

      <div>
        <b-col md="6" class="pl-0 mb-1">
          <b-button v-if="isEditor" class="mr-1" @click="toggleShowCode(placement.id)">Код установки</b-button>
          <b-button v-if="isEditor" @click="toggleShowVast(placement.id)">Получить VAST</b-button>
        </b-col>

        <b-col sm="3" class="pl-0">
        <loader-button
            type="submit"
            variant="primary"
            :disabled="isSubmitting"
            :is-submitting="isSubmitting"
        >
          {{ isEditor ? 'Сохранить' : 'Создать' }}
        </loader-button>
        </b-col>

      </div>
    </BForm>
  </validation-observer>
</template>

<script>
import ToFilterSelect from "@/components/ToFilterSelect"
import {dynamicRegex} from "@core/utils/validations/validators"
import MultiselectCheckboxesWithValidation from "@/components/MultiselectCheckboxesWithValidation"
import {
  BForm,
  BButton,
  BFormSelect,
  BFormInput,
  BFormSelectOption,
  BFormTextarea,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BFormRadio,
  BFormGroup,
  BFormCheckbox
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import FormInput from '@/components/FormInput'
import FormInputWrap from '@/components/FormInputWrap'
import LoaderButton from '@/components/LoaderButton'
import MultipleSelect from '@/components/MultipleSelect'
import Multiselect from 'vue-multiselect'

import * as validations from '@validations'
import { ValidationProvider } from 'vee-validate'
import {
  fetchAdvertisementType, fetchPlatformsList
} from '@/api/lists'
import MultiselectValidation from "@/components/MultiselectWithValidation"
import {useUtils as useAclUtils} from "@core/libs/acl"
import {objToQuery} from "@core/utils/utils"
import statisticLists from "@/use/statisticLists"
import {ref} from "@vue/composition-api/dist/vue-composition-api"
import {reactive} from "@vue/composition-api"


export default {
  name: 'PlacementsForm',
  components: {
    MultiselectValidation,
    ToFilterSelect,
    MultiselectCheckboxesWithValidation,
    ValidationProvider,
    BFormCheckbox,
    BForm,
    BFormInput,
    BFormRadio,
    BFormGroup,
    BButton,
    BFormSelect,
    BFormTextarea,
    BFormSelectOption,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSpinner,
    ValidationObserver,
    FormInput,
    FormInputWrap,
    LoaderButton,
    MultipleSelect,
    Multiselect,
  },

  setup(props, { emit }) {

    const {
      platformsList,
      advertisementTypesList,
      advertisersList,
      publishersList,
      namedLists,
      campaignsList,
      handleListsSearch,
      handleLoadLists,
      handlePaginateList,
      setQuery,
      getQuery
    } = statisticLists()

    const { can } = useAclUtils()
    const isLoadingLists = ref(true)
    const isLoadingCampaigns = ref(false);
    const filterToShow = ref('campaigns')
    const roleId = parseInt(localStorage.getItem('userRoleId'), 10)

    const publisher_id = ref([])
    const formData = reactive({
      name: '',
      platform_id: [],
      advertisement_types: [],
      publisher_commission_static: null,
      publisher_commission_dynamic: null,
      must_verify_traffic: false,
      campaigns: [],
    })

    const pickAdvertiser = (value, toLoad = true) => {
      pickedAdvertiser.value = value;
      setQuery('campaigns', 'advertisers', value);
      if(toLoad) handleLoadLists(['campaigns']);
      // queryCampaignObj.advertisers = value;
    }

    // refactor to composition
    const isSubmitting = ref(false)
    const isEditor = ref(false)

    const form = ref(null);
    const comissionType = ref('static');
    const pickedAdvertiser = ref([]);


    if (props.placement && Object.keys(props.placement).length) {
      isEditor.value = true;
      comissionType.value = props.placement.publisher_commission_type.id === 2 ? 'dynamic' : 'static';
      formData.name = props.placement.name || ''
      formData.id = props.placement.id || null

      pickAdvertiser(props.placement.advertisers && props.placement.advertisers.map((item) => item.id) || [], false);

      publisher_id.value = props.placement.publisher && [props.placement.publisher.id] || []
      formData.platform_id = [props.placement.platform?.id] || null

      formData.advertisement_types =  props.placement.advertisement_types &&
          props.placement.advertisement_types.length && props.placement.advertisement_types.map((item) => item.id) || []
      setQuery('campaigns', 'adv_types', props.placement.advertisement_types &&
          props.placement.advertisement_types.length && props.placement.advertisement_types.map((item) => item.id) || []);

      if(props.placement.publisher_commission_static) {
        formData.publisher_commission_static = props.placement.publisher_commission_static
        setQuery('campaigns', 'cpm', props.placement.publisher_commission_static);
      }

      if(props.placement.publisher_commission_dynamic) {
        formData.publisher_commission_static = props.placement.publisher_commission_dynamic + '%'
        if (props.placement.publisher_commission_dynamic) setQuery('campaigns', 'cpm', '0');
      }

      formData.campaigns = props.placement.campaigns &&
          props.placement.campaigns.length && props.placement.campaigns.map((item) => item.id) || []
    }

    let filtersOptions = [
      {
        text: 'Площадка',
        value: 'platforms'
      },
      {
        text: 'Тип рекламы',
        value: 'advertisement_types'
      },
    ]

    const proxyForFilterSelect = {
      platforms: 'platform_id',
      advertisement_types: 'advertisement_types',
      campaigns: 'campaigns',
    }

    let toLoadLists = ['platforms']

    const addRolesOptions = () => {
      if(can('read', 'publishers-list')) {
        filtersOptions.unshift({
          text: 'Паблишер',
          value: 'publishers'
        })
        toLoadLists.push('publishers');
      }
      if(can('manage', 'advertising-campaigns-placements')) {
        filtersOptions.push({
          text: 'РК',
          value: 'campaigns'
        })
        toLoadLists.push('campaigns');
        toLoadLists.push('dsps');
      }
    }

    addRolesOptions()

    handleLoadLists(toLoadLists, isLoadingLists)

    // handleLoadCampaignList(isEditor.value)

    filterToShow.value = filtersOptions[0].value


    fetchAdvertisementType()
      .then(value => {
        advertisementTypesList.value = value.data.data
      })


    const setFilterToShow = (value) => {
      filterToShow.value = value;
    }

    const pickersFor = (name, payload) => {
      switch (name) {
        case 'dsps' :
          return pickAdvertiser(payload)

        default:
          return pickMultiselect(payload, name)
      }
    }

    const pickAllItems = (name, state) => {
      if(state) {
        setQuery(name, 'all', '1');
        handleLoadLists([name], isLoadingLists)
            .then((res) => {
              const allIds = res.map((item) => item.id)
              pickersFor(name, allIds);
            })
      } else {
          pickersFor(name, [])
      }
    }

    const comissionLabel = ref('CPM')

    const setComissionLabel = (val) => {
      comissionLabel.value = dynamicRegex.test(val) ? 'Динамический CPM' : 'Статический CPM'
    }

    const setComission = (value) => {
      if(dynamicRegex.test(value)) return;
      if(getQuery('campaigns', 'cpm') !== value) formData.campaigns = [];
      setQuery('campaigns', 'cpm', value.replace(',', '.'));
      // queryCampaignObj.cpm = value.replace(',', '.');
    }


    const pickMultiselect = (value, name) => {
      formData[name] = value;
    }

    const pickPublisher = (value, name) => {
      // console.log(value)
      // console.log(name);
      publisher_id.value = value;
      formData.platform_id = null;
      isLoadingLists.value = true;
      // this.showPlatformTooltip = false;

      updatePlatforms()
    }

    const pickAdvertisementTypes = (value, name) => {
      formData[name] = value;
      setQuery('campaigns', 'adv_types', value);
      // queryCampaignObj.adv_types = value;
    }

    const updatePlatforms = () => {
      fetchPlatformsList(objToQuery({'publisher': [publisher_id.value]}))
          .then((res) => {
            platformsList.value = res.data.data
            if(!res.data.data.length) this.showPlatformTooltip = true;
          })
          .finally(() => {
            isLoadingLists.value = false;
          })
    }

    const disableForAdvertisementType = (el) => {
      if(formData.advertisement_types.length === 0) return false;
      switch(el.id){
        case 1:
        case 2:
          if(formData.advertisement_types.includes(3) || formData.advertisement_types.includes(4)) {
            return true
          }
          break;
        case 3:
          if(formData.advertisement_types.includes(1) || formData.advertisement_types.includes(2) || formData.advertisement_types.includes(4))
            return true
          break;
        case 4:
          if(formData.advertisement_types.includes(1) || formData.advertisement_types.includes(2) || formData.advertisement_types.includes(3))
            return true
          break;
        default:
          if(formData.advertisement_types.includes(1) || formData.advertisement_types.includes(2)) return true
          break;
      }
    }

    const handleSubmit = async () => {
      const isValid = await form.value.validate();
      if(isValid) {
        const requestData = {
          ...formData,
          publisher_commission_static: dynamicRegex.test(formData.publisher_commission_static) ? null : formData.publisher_commission_static.replace(',', '.'),
          publisher_commission_dynamic: dynamicRegex.test(formData.publisher_commission_static) ? formData.publisher_commission_static.slice(0, -1) : null
        }

        for (let prop in requestData) {
          if (Array.isArray(requestData[prop]) && requestData[prop].length) {
            requestData[prop] = requestData[prop].join(',')
          }
        }

        emit('submit', {...requestData})
      }
    }


    return {
      can,
      isLoadingLists,
      pickMultiselect,
      isLoadingCampaigns,
      proxyForFilterSelect,
      comissionType,
      form,
      pickPublisher,
      handleSubmit,
      disableForAdvertisementType,
      pickAdvertisementTypes,
      // queryCampaignObj,
      isSubmitting,
      isEditor,
      publisher_id,
      setComission,
      formData,
      platformsList,
      pickAdvertiser,
      pickedAdvertiser,
      advertisersList,
      advertisementTypesList,
      campaignsList,
      publishersList,
      namedLists,
      handleListsSearch,
      handleLoadLists,
      pickAllItems,
      handlePaginateList,
      // handleCampaignSearch,
      filterToShow,
      filtersOptions,
      ...validations,
      setFilterToShow,
      comissionLabel,
      setComissionLabel
    }
  },
  props: {
    isSubmitting: {
      type: Boolean,
      required: false,
      default: () => false
    },
    validationSchema: {
      type: Object,
      required: true
    },
    placement: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      comissionCheck: false,
      showPlatformTooltip: false,
      // ...validations
    }
  },
  methods: {
    toggleShowCode(id) {
      this.$emit('showCode', id);
    },

    toggleShowVast(id) {
      this.$emit('showVast', id);
    },
  }
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">

.placements-form {
  & .multiselect {

    &__items {
      height: 150px;
    }
  }

  &__select-col {
    padding-right: 2rem;
  }
}

.campaign__dropdown {
  width: 100%;
}

.campaign__dropdown .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.selected-item {
  margin: 3px;
  cursor: pointer;
}

.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px;
}
</style>
