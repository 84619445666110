
export function activeToggle() {

    const handleToggleActive = (data, activate, deactivate) => {
        const request = data.is_active ? deactivate : activate
        request(data.id)
            .then(() => {
                // this.$toast(getToastificationSuccessParams('Пользователь успешно создан'))
                // разобраться почему не работают тосты
            })
            .catch((e) => {
                console.log(e)
                // this.$toast(getToastificationErrorParams('Что-то пошло не так'))
            })
    }
    return {handleToggleActive}
}
