<template>
  <div>
    <b-dropdown class="custom-dropdown" text="Выбрать" block toggle-class="custom-dropdown-button">
      <b-dropdown-item v-for="item in options" :key="item.id" @click="handleChange(item)">
        {{ item.name }}
      </b-dropdown-item>
    </b-dropdown>

    <div class="mt-1">
      <b-badge
        v-for="badge of value"
        :key="badge.id"
        class="selected-item"
        variant="primary"
        @click="handleChange(badge)"
      >
        {{ badge.name }}
      </b-badge>
    </div>
  </div>
</template>

<script>
import { BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: { BBadge, BDropdown, BDropdownItem },
  props: {
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  methods: {
    handleChange(item) {
      const isAlreadySelected = !!this.value.find((i) => i.id === item.id)

      if (isAlreadySelected) {
        const newValue = this.value.filter((i) => i.id !== item.id)
        this.$emit('input', newValue)
      } else {
        const newValue = [...this.value, item]
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="scss">
.custom-dropdown .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.custom-dropdown .custom-dropdown-button {
  display: flex;
  justify-content: space-between;
}
</style>
