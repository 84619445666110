<template>
  <div>
    <div class="d-flex align-items-center">
      <b-button
          variant="primary"
          class="btn-icon mr-1"
          @click="handleBack"
      >
        <feather-icon
            icon="ArrowLeftIcon"
            size="16"
        />
      </b-button>
      <h3>{{title}}</h3>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import {BButton} from "bootstrap-vue"
export default {
  name: 'CampaignFullPageForm',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    BButton
  },
  methods: {
    handleBack() {
      this.$emit('close');
    }
  }
}
</script>
