<template>
  <InlineSvg
      v-if="src"
      :src="src"
      class="custom-icons"
  />
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
  name: 'CustomIcons',
  components: {
    InlineSvg
  },
  props: {
    name: String
  },
  data() {
    return {
      src: ''
    }
  },
  created() {
   import(`../assets/icons/${this.name}.svg`)
       .then((res) => {
         this.src = res.default;
       })

  }
}
</script>

<style lang="scss">
//.router-link-active .custom-icons {
//  & path {
//    fill: white;
//  }
//}
//.main-menu.menu-light .navigation > li.active > a .custom-icons {
//  & path {
//    stroke: white;
//  }
//}
</style>
