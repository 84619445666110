<template>
  <b-button :type="type" :variant="variant" block :disabled="disabled || isSubmitting">
    <div v-if="isSubmitting" class="d-flex w-100 justify-content-center">
      <b-spinner class="mr-1" small :variant="spinnerVariant" label="Отправка" />
      <span>Отправка</span>
    </div>
    <slot v-else />
  </b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: { BButton, BSpinner },
  props: {
    type: {
      type: String,
      required: false,
      default: () => 'button'
    },
    variant: {
      type: String,
      required: false,
      default: () => 'primary'
    },
    spinnerVariant: {
      type: String,
      required: false,
      default: () => 'light'
    },
    isSubmitting: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
