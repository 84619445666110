import api from '../libs/axios'

export const fetchPlacements = (query) => api.get(`/api/placement${query ? `/?${query}` : ''}`)

export const createPlacement = (data) => api.post('api/placement', {...data})

export const editPlacement = (data) => api.patch(`/api/placement/${data.id}`, {...data})

export const activatePlacement = (id) => api.post(`api/placement/${id}/activate`)

export const deactivatePlacement = (id) => api.post(`api/placement/${id}/deactivate`)

