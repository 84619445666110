<template>
  <b-sidebar
    :visible="visible"
    id="create-user-sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    right
    shadow
    no-header
    backdrop
    @hidden="handleCloseSidebar"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ title }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <div class="px-2">
        <slot />
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BFormSelect, BFormSelectOption } from 'bootstrap-vue'

export default {
  components: {
    BSidebar,
    BFormSelect,
    BFormSelectOption
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false
    },
    title: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        document.body.classList.add('overflow-hidden', 'h-100')
      } else {
        document.body.classList.remove('overflow-hidden', 'h-100')
      }
    }
  },
  methods: {
    handleCloseSidebar() {
      this.$emit('hide')
    }
  },
  beforeDestroy() {
    document.body.classList.remove('overflow-hidden', 'h-100')
  }
}
</script>
