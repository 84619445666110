import ToastificationContent from '@core/components/toastification/ToastificationContent'

function getParams(props = {}) {
  return {
    component: ToastificationContent,
    position: 'top-right',
    props: { icon: 'BellIcon', ...props }
  }
}

export function getToastificationErrorParams(text = '') {
  return getParams({
    title: 'Ошибка',
    variant: 'danger',
    text
  })
}

export function getToastificationParams(text = '', title = '') {
  return getParams({
    title,
    text,
    variant: 'primary'
  })
}

export function getToastificationSuccessParams(text = '') {
  return getParams({
    title: 'Успех',
    variant: 'success',
    text
  })
}
