import {reactive, watch} from "@vue/composition-api"
import {objToQuery} from "@core/utils/utils"


export const queries = (fetch, ...ctx) => {

    const queryObj = reactive({
        is_active: null,
        nameScope: null,
        categoriesScope: null,
        field: null,
        order: null,
        platforms: null,
        placements: null,
        campaigns: null,
        advertisement_types: null,
        advertiser_id: null,
        user_id: null,
        role: null
    })

    if (ctx[0]) {
        const route = ctx[0].root.$route

        if (route.params && Object.keys(route.params).length) {
            for (const key in route.params) {
                queryObj[key] = route.params[key]
            }
            fetch(objToQuery(queryObj))
        } else if (ctx[1] && ctx[1] !== 'notPreload') {
            const store = ctx[1]
            const preseted = store.state[ctx[2]].filters

            for (const key in preseted) {
                queryObj[key] = preseted[key]
            }
            fetch(objToQuery(queryObj))
        }
    }


    watch(
        () => queryObj,
        () => {
            console.log('q')
            fetch(objToQuery(queryObj))
        },
        {deep: true}
    )

    return { queryObj }
}
