import {ref} from "@vue/composition-api"

export function search(queryObj, store, name) {

    let debounce = null

    const searchRef = ref('')

    if (name === 'campaigns' && store.getters['campaigns/GET_SEARCH']) {
        searchRef.value = store.getters['campaigns/GET_SEARCH']
    }

    if (name === 'placements' && store.getters['placements/GET_SEARCH']) {
        searchRef.value = store.getters['placements/GET_SEARCH']
    }

    const startSearch = () => {
        if (searchRef.value.length >= 2 || searchRef.value.length === 0 || +searchRef.value) {
            clearTimeout(debounce)
            debounce = setTimeout(() => {
            queryObj.nameScope = searchRef.value
            }, 600)
        }
    }

    return {searchRef, startSearch}
}
